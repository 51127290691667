import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Stage } from "../../pages/live-event-form/live-event-form.component";

@Component({
    selector: "zx-live-event-stage-table",
    templateUrl: "./live-event-stage-table.component.html"
})
export class LiveEventStageTableComponent implements OnInit {
    @ViewChild("form") form: NgForm;
    @Input() stages: Stage[] = [{ name: null, duration: null, force_channel_slate: false, clip_id: null }];
    @Input() clips: { name: string; url: string }[] = [];
    @Input() submitted: boolean;
    @Input() isStagingAuto = true;
    @Output() stagesChanged = new EventEmitter<Stage[]>();

    duplicateNamesIndex: number[] = [];

    ngOnInit(): void {
        this.validateAndEmitStage(true);
    }

    addStage() {
        this.stages.push({
            name: null,
            duration: null,
            force_channel_slate: false,
            clip_id: null
        });
        this.stagesChanged.emit(this.stages);
    }

    deselectStage(deselectIndex) {
        this.stages = this.stages.filter((_, index) => index !== deselectIndex);
        this.validateAndEmitStage(true);
    }

    validateAndEmitStage(nameChanged?: boolean) {
        if (nameChanged) {
            this.findDuplicateNamesIndex();
            setTimeout(() => {
                for (let i = 0; i < this.stages.length; i++) {
                    const stageNameControl = this.form.form.controls[`stageName_${i}`];
                    if (this.duplicateNamesIndex.includes(i)) {
                        stageNameControl.setErrors({ notUnique: true });
                    } else if (stageNameControl.value === null) {
                        stageNameControl.setErrors({ required: true });
                    } else {
                        stageNameControl.setErrors(null);
                    }
                }
            });
        }

        this.stagesChanged.emit(this.stages);
    }

    findDuplicateNamesIndex() {
        this.duplicateNamesIndex = [];
        for (const [index, stage] of this.stages.entries()) {
            if (stage.name !== null) {
                for (let i = index + 1; i < this.stages.length; i++) {
                    const otherStage = this.stages[i];
                    if (stage.name === otherStage.name && index !== i) {
                        this.duplicateNamesIndex.push(...[index, i]);
                    }
                }
            }
        }
        this.duplicateNamesIndex = [...new Set(this.duplicateNamesIndex)];
    }
}
